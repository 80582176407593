.modal-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 50;

    &::before {
        content: "";
        background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
        position: absolute;
        display: flex;
    }

    .modal-card {
        border-radius: 10px;
        justify-content: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        background-color: $white-color;
        position: relative;
        padding: 0 51px;
        width: 537px;
        height: 432px;
        margin-top: 79px;
        margin: 0 auto;
        @include slide-up-animation(0.3s, ease-in);
        @include flexCol;

    h1 {
      color: #000;
      @include regularTextStyling(32px, normal, $font-500);
    }
    p {
      color: #636363;
      margin-top: 16px;
      @include regularTextStyling(16px, 20px, 400);
    }
  }
}
