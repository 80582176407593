.header {
  width: 100%;
  padding: 24px 40px 23px 30px;
  @include generateBorderAttr(border-bottom, 1px, solid, $primary-tint-6);

  &__content {
    @include media(">1440px") {
      justify-content: space-between;
    }
  }

  &__title {
    margin-left: 10px;
    color: $black-3-color;
    @include regularTextStyling(1.3em, normal, 500);
  }
}
.search-wrapper {
  width: 746px;
  border-radius: 8px;
  @include generateBorderAttr($color: $primary-tint-6);
  padding: 10px 15px;

  &:hover {
    border: 1px solid #c6c2de;
    background-color: #fff;
  }

  & input {
    background-color: transparent;
    width: 100%;
    margin-left: 8px;

    &::placeholder {
      color: #718096;
      @include regularTextStyling($text-sm, normal, 400);
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  &:hover {
    border: 1px solid #c6c2de;
    background-color: #fff;
  }
}
.icon-wrapper {
  width: 37px;
  height: 37px;
  border-radius: 100%;
  margin: 0 32px;
  @include displayFlex();
  @include generateBorderAttr($color: #cbd5e0);
}
.profile-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  // margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }

  & input {
    background-color: transparent;
    width: 100%;
    margin-left: 8px;

    &::placeholder {
      color: #718096;
      @include regularTextStyling($text-sm, normal, 400);
    }
    &:focus {
      border: none;
      outline: none;
      cursor: progress;
    }
  }
}

span {
  @include regularTextStyling($text-sm, 20px, 400);
  color: $black-100;
}

.profile__btn {
  padding: 7px 10px;
  background-color: transparent;
  border: 1px solid #000;
  outline: none;
  border-radius: 5px;
  font-size: 11px;
}