@import "./styles/variable.css";

.onboarding {
  background-color: var(--white-color);
  padding: 39px 122px 52px 119px;
}

.onboarding-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 82px;
}

.onboarding-heading span {
  color: var(--black-3-color);
  font-size: var(--font-size);
  font-weight: 400;
}
.form-wrapper {
  width: 375px;
  max-width: 100%;
  margin: auto;
}
.form-wrapper h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--black-3-color);
  margin-bottom: 28px;
}
.form-wrapper h2,
.form-wrapper h1 {
  font-size: 24px;
  font-weight: 500;
  color: var(--black-3-color);
}
.form-wrapper span {
  color: #636363;
  font-size: 12px;
  font-weight: 400;
}
.form-wrapper p {
  margin-bottom: 34px;
}

.input-container {
  display: flex;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  border: 1px solid var(--black-1-color);
  margin-bottom:4px;
  overflow: hidden;
}

.input-border,
.date-container.input-border,
.select-box.input-border {
  border: 1px solid black;
}

.input-container img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  align-self: center;
}
.input-container .password {
  width: 16px;
  height: 16px;
  object-fit: cover;
  align-self: center;
}
.file-info {
  color: #abafb1 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.file-margin {
  margin-bottom: 0;
  width: 100%;
}
.date-container {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--black-1-color);
  
}
.date-container input {
  width: 100%;
}
.date-container input:focus {
  outline: none;
  border: none;
}
.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--black-1-color);
  margin-bottom: 10px;
}
.input-container:hover {
  border: 1px solid var(--black-3-color) !important;
}
.input-container div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.input-container .text-field {
  border: none;
  outline: none;
}

.text-field::placeholder,
.selected-option,
.PhoneInputInput::placeholder,
textarea::placeholder,
input[type="date"]::placeholder,
.date-container input,
.custom-file-input .placeholder {
  color: var(--black-2-color);
  font-size: var(--font-size);
  font-weight: 400;
}
.input-container label,
.select-box label,
.date-container label,
.custom-file-input label {
  color: var(--black-4-color);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}

.v1-label {color: var(--black-4-color);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;}

.option-wrapper {
  padding: 20px 16px;
  border: 1px solid var(--black-1-color);
  height: 182px;
  overflow-y: auto;
  max-width: 378px;
  border-radius: 8px;
  z-index: 50;
  background-color: var(--white-color);
}
.options p {
  color: var(--black-5-color);
  font-size: var(--font-size);
  font-weight: 400;
  margin-bottom: 10px;
}
.agree-wrapper {
  display: flex;
  margin-bottom: 25px;
}
.agree-wrapper span {
  color: #a1a1a1;
  font-size: 10px;
  margin-left: 8px;
}
.agree-wrapper span a,
.onboarding-heading a {
  color: var(--black-3-color);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

button {
  color: #878787;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 5px;
}

button:hover:enabled {
  opacity: 0.85 !important;
}

.phone-input-container {
  position: relative;
}
.phone-input-container::after {
  content: "Phone Number";
  position: absolute;
  top: -40px;
  left: 140px;
  color: var(--black-4-color);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 50px;
}

.PhoneInputInput {
  padding: 20px 16px !important;
  border-radius: 8px;
  border: 1px solid var(--black-1-color);
}
.PhoneInputInput:focus,
.PhoneInputCountry:hover {
  border: 1px solid var(--black-3-color) !important ;
  outline: none;
}

.PhoneInputCountry {
  border: 1px solid var(--black-1-color) !important;
  padding-left: 18px;
  padding-right: 10px;
  border-radius: 8px;
}
input {
  background-color: transparent !important;
}
input[type="email"],
input[type="password"] {
  background-color: transparent !important; /* Set your desired background color here */
}

.verification-wrapper {
  margin: auto;
  width: 25%;
}

.verification-wrapper-margin {
  margin: 153px auto;
}
.verification-wrapper h4 {
  font-size: var(--text-2xl);
  font-weight: var(--font-medium);
  color: var(--black-3-color);
}
.verification-wrapper p,
.form-wrapper p {
  font-size: 14px;
  color: #636363;
  margin-top: 5px;
}
.verification-wrapper p:last-of-type {
  margin-bottom: 30px;
}
.verification-wrapper p:last-of-type a {
  color: var(--black-3-color);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 2px;
}

.verification-wrapper input:focus {
  outline: none;
}
.verification-wrapper img {
  margin-bottom: 32px;
}
.verification-wrapper h5 {
  font-size: 30px;
  font-weight: var(--font-medium);
}

.business-header .business-content {
  border-radius: 7.526px;
  min-width: 233px;
  border: 1px dashed #adadad;
  background: #f1f1f1;
  padding: 10px 10px;
}
.business-content p {
  color: #737991;
  font-size: 11.707px;
  font-weight: var(--font-medium);
}
.business-content h2 {
  color: var(--black-3-color);
  font-size: 15.052px;
}
.business-content.tab-active {
  background: #01b72f;
}

.business-content.tab-active p,
.business-content.tab-active h2 {
  color: var(--white-color);
}

textarea:focus {
  outline: none;
  border: none;
}

.custom-file-input {
  position: relative;
  display: inline-block;
}

.custom-file-input input[type="file"] {
  position: absolute;
  left: -9999px;
}

/* ************* Table ******************** */
.table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


table {
      border-collapse: collapse;
      width: 100%;
      margin-left: -15px;
}



table  {
display: grid;
    border-collapse: collapse;
      width: 100%;
      margin-left: -15px;
  grid-template-columns:
  minmax(165px, 1fr) minmax(110px, 110px) minmax(110px, 110px) 
  minmax(150px, 1fr) minmax(180px, 1fr) minmax(100px, 1fr);
    
}

table.four-column-table {
  display: grid;
;
  grid-template-columns:
    minmax(100px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(150px, 1fr);
 
}

thead,
tbody,
tr {
  display: contents;
}

thead tr {
  position: relative;
}

th,
td {
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

td button {
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  outline: none;
  border-radius: 5px;
  border: none;
  font-size: 0.9em;
  font-family: inherit;
  cursor: pointer;
}

td .status {
  padding: 5px 10px;
  outline: none;
  border-radius: 5px;
  border: none;
  font-size: 0.9em;
  font-family: inherit;
}

th {
  position: sticky;
  width: 100%;
  top: 0;
  padding: 15px;
  text-align: left;
  font-weight: normal;
  color: #b9b9b9;
  font-size: 0.8em;
  border-bottom: 1px solid #e5e5e5;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0;
  width: 3px;
  cursor: col-resize;
}

.resize-handle:hover,
/* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
.header--being-resized .resize-handle {
  opacity: 0.5;
}

th:hover .resize-handle {
  opacity: 0.3;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  font-size: 0.8em;
  border-bottom: 1px solid #e5e5e5;
}
