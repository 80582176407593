$primary-tint-6: #eeeff7;
$fontFamily: "Poppins", sans-serif;
$white-color: #fff;
$text-2xl: 24px;
$text-sm: 14px;
$text-xl: 12px;
$black: #000;
$black-3-color: #333;
$black-100: #1c1c1c;
$black-5: rgba(0, 0, 0, 0.05);
$black-8: rgba(0, 0, 0, 0.3);
$font-600: 600;
$font-500: 500;
