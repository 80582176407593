:root {
  --main-color: #007bff;
  --white-color: #fff;
  --black-color: #344054;
  --black-3-color: #333;
  --black-4-color: #5e6366;
  --black-1-color: #cfd3d4;
  --black-2-color: #abafb1;
  --black-5-color: #2b2f32;
  --font-size: 14px;
  --text-2xl: 24px;
  --border-radius: 4px;
  --font-medium: 500;
}
