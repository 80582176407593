.get-funding {
  width: 100%;
  margin: 0 29px;

  &__options {
    
    gap: 24px 16px;
    margin-top: 39px;
  }
  &__settings {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 24px 16px;
    margin-top: 25px;
  }
}

.complete-business-form {
  margin-top: 35px;
}
.profile {
  

  h1 {
    color: $black-3-color;
    @include regularTextStyling($text-2xl, normal, $font-500);
  }
  h2 {
    margin-bottom: 16px;
    color: $black-3-color;
    @include regularTextStyling(20px, normal, $font-500);
  }

  p {
    color: #636363;
    @include regularTextStyling($text-xl, normal, 400);
    margin: 8px 0 34px 0;
    padding-right: 2.5rem;
  }
  .input-grid {
    display: flex;
    flex-wrap: wrap;
    
    // display: grid;
    // grid-template-columns: 48.5% 48.5%;
    column-gap: 24px;
  }
  .business-location-heading {
    color: #636363;
    margin-bottom: 24px;
    @include regularTextStyling(15px, normal, 500);
  }
}
.shareholder {
  width: 1000px;
  margin-left: 100px;

  &__input-grid {
    display: grid;
    grid-template-columns: 180px 183px 181px 132px 132px 132px;
    gap: 12px;
  }

  &__add {
    color: $black-3-color;
    @include regularTextStyling(16px, normal, $font-500);
  }
}

.invoice {
  &__add {
    color: #000;
    margin-bottom: 34px;
    @include regularTextStyling($text-sm, normal, 500);
  }
}

.guarantor {
  h1 {
    color: $black-3-color;
    @include regularTextStyling(24px, normal, $font-500);
  }

  p {
    color: #636363;
    @include regularTextStyling($text-xl, normal, 400);
    margin: 8px 0 34px 0;
    padding: 2.5rem;
  }

  &__input-grid {
    display: grid;
    grid-template-columns: 352px 352px 358px;
    gap: 12px;
  }

  &__add {
    color: $black-3-color;
    @include regularTextStyling(16px, normal, $font-500);
  }
}

// ================= Repayment =========================
.repayment {
  width: 840px;

  &__table {
    .table {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* padding: 50px; */
    }

    table {
      display: grid;
      border-collapse: collapse;
      width: 100%;
      margin-left: -15px;
      grid-template-columns: minmax(100px, 1fr) minmax(150px, 1fr) minmax(
          100px,
          1fr
        );
    }

    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    th {
      position: sticky;
      top: 0;
      padding: 15px;
      text-align: left;
      font-weight: normal;
      color: #b9b9b9;
      border-bottom: 1px solid #e5e5e5;
    }

    .resize-handle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: black;
      opacity: 0;
      width: 3px;
      cursor: col-resize;
    }

    .resize-handle:hover,
    /* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
    .header--being-resized .resize-handle {
      opacity: 0.5;
    }

    th:hover .resize-handle {
      opacity: 0.3;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #000000;

      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
}

/* styles.css or styles.module.css */
.buttonStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
  color: #fff;
  height: 100px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 9px;
}
