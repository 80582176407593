.credit-score {
  &__calc-title {
    color: #333;
    @include regularTextStyling(16px, 20px, $font-600);
  }

  // padding: 31px 0 54px 29px;
  &__calc-title {
    color: #333;
    margin-top: 39px;
    @include regularTextStyling(16px, 20px, $font-600);
  }
  &__info {
    h1 {
      margin: 52px 0 24px 0;
    }
  }
  &__info-wrapper {
    display: flex;
    gap: 20px
  }
  &__card {
    display: flex;
    
    gap: 24px 20px;
    margin-top: 24px;

    div {
      padding: 17px 38px 20px 11px;
      
      min-width: 256px;
      max-width: 256px;
      border-radius: 9px;
      background: #f0f0f0;
      h1 {
        color: #1f1f1f;

        @include regularTextStyling($text-sm, 100%, $font-500);
      }
      .percentage {
        @apply my-5;
        @include regularTextStyling(28px, normal, $font-500);
        color: #01b72f;
      }
      .info {
        color: #9a9a9a;
        @include regularTextStyling(12px, 140%, 400);
      }
    }
  }
}
