// @mixin activeSidebarGradient() {
//     background: linear-gradient(
//         231.68deg,
//         rgba(255, 102, 122, 0.98) -5.8%,
//         #ff82b1 60.6%,
//         #ffa3e6 103.6%,
//         #ffc6ff 151.5%
//     );
// }

@mixin regularTextStyling($fsize: $fontSize, $lheight: normal, $fweight: normal) {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: $fweight;
    font-size: $fsize;
    line-height: $lheight;
}

// @mixin iconGradientColor($startColor: #efdbff, $midColor: #d3adf7, $endColor: #b37feb) {
//     background: linear-gradient(180deg, $startColor 0%, $midColor 51.56%, $endColor 100%);
// }

// @mixin loadTransition($easing: ease-in-out) {
//     transition: 0.2s ease-in-out;
// }

// @mixin loadTransitionAll($easing: ease-in-out) {
//     transition: all 0.2s #{$easing};
// }

// @mixin addTransitionFor($for, $duration: 0.2s, $easing: ease-in-out) {
//     transition: #{$for} #{$duration} #{$easing};
// }

@mixin displayFlex($alignItems: center, $justifyContent: center) {
    display: flex;
    align-items: $alignItems;
    justify-content: $justifyContent;
}

@mixin generateSpacingAttr($type: padding, $direction: left, $value: 10) {
    #{$type}-#{$direction}: #{$value}px;
}

@mixin generateBorderAttr($type: border, $width: 1px, $style: solid, $color: $color) {
    #{$type}: #{$width} #{$style} #{$color};
}

@mixin resetListStyle {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin calculateHeight($init: 100%, $final, $operator) {
    height: calc(#{$init} #{$operator} #{$final});
}

@mixin removeOutline {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

@mixin widthMaxWidth($width: 100%, $maxWidth: 100%) {
    width: $width;
    max-width: $maxWidth;
}

@mixin generalButtonStyling($bgColor: $primaryColor) {
    background: $bgColor;
    border-radius: 8px;
    padding: 13px 20px;
    @include regularTextStyling();
    color: $whiteColor;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    @include addTransitionFor(background);
}

@mixin flex {
    display: flex;
    align-items: center;
    background-color: black;
}
@mixin flexCol {
    display: flex;
    flex-direction: column;
}
@mixin slide-up-animation($duration, $transition) {
    animation: slideUp 0.3s $transition forwards;
    @keyframes slideUp {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }
}

@mixin slide-down-animation($duration, $transition) {
    animation: slideDown $duration $transition forwards;
    @keyframes slideDown {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }
}

@mixin slide-left-animation($duration, $transition) {
    animation: slideLeft $duration $transition forwards;
    @keyframes slideLeft {
        from {
            transform: translateX(500px);
        }
        to {
            transform: translateX(-20%);
        }
    }
}

@mixin slide-right-animation($duration, $transition) {
    animation: slideRight $duration $transition forwards;
    @keyframes slideRight {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }
}

@mixin fade-in-animation($duration, $transition) {
    animation: fadeIn $duration $transition forwards;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin fade-out-animation($duration, $transition) {
    animation: fadeOut $duration $transition forwards;
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}

@mixin border($color) {
    border: 1px solid $color !important;
}
