@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/mixin";
@import "./styles/variable";
@import "./styles/mediaquery";
@import "./styles/sidebar";
@import "./styles/header";
@import "./styles/main";
@import "./styles/funding";
@import "./styles/Modal";
@import "./styles/creditScore";
@import "./styles/loader";