@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa&family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;




* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}

.phone-input-v1 .PhoneInputInput {
  height: 58px;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: unset !important;
  scroll-behavior: smooth !important;

}

::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #a4a1a16f;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #55555563;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #159AA8;
  /* Add a border for visibility */
  border-radius: 4px;
  /* Add rounded corners */
  padding: 10px;
}

.otp-input-container input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border: none;
  outline: none;
  margin: 5px;
}