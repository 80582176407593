.no-transaction {
  div {
    font-family: "poppins";
    text-align: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    @include flexCol();

    p {
      color: #636363;
      margin-top: 8px;
      font-size: 12px;
    }

    &__title-2,
    h2 {
      color: #000;
      @include regularTextStyling(16px, normal, 600);
    }
    h1 {
      color: #000;

      @include regularTextStyling(18px, normal, 600);
    }
  }
}

.main {
  justify-content: space-between;

  &__left {
    width: 75%;
    @apply ml-6;
  }

  &__right {
    width: 25%;
    flex-grow: 0;
  }
}
.transaction {
  overflow: scroll;
  padding-top: 40px;
  .title-1 {
    color: $black-3-color;
    @include regularTextStyling(16px, 20px, $font-600);
  }
}

.summary-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  padding: 20px;
  background-color: $black;

  h5 {
    color: #fff;
    @include regularTextStyling($text-sm, 20px, $font-600);
  }

  .amount {
    margin-top: 4px;
    color: #fff;
    @include regularTextStyling($text-2xl, normal, $font-600);
  }

  &__button-wrapper {
    margin-top: 13px;
    @include displayFlex(center, flex-start);

    div {
      border-radius: 3.451px;
      padding: 6px 12px;
      @include regularTextStyling(8.627px, normal, 400);

      &:first-child {
        background-color: #fff;
        color: #000;
        margin-right: 4px;
      }

      &:last-child {
        background: #525252;
        color: #fff;
      }
    }
  }
}

.credit-card {
  width: 100%;
  padding: 20px 24px;
  border-radius: 16px;

  &__header {
    @include displayFlex(center, space-between);
  }

  &__date-info {
    border-radius: 4px;
    padding: 5px 13px;
    background: rgba(255, 255, 255, 0.25);
    @include regularTextStyling(12px, normal, 400);
  }

  h1 {
    margin-top: 8px;

    @include regularTextStyling($text-2xl, normal, $font-600);
  }
  sub {
    @include regularTextStyling(10px, normal, 400);
  }

  &__progress-wrapper {
    .label {
      p {
        @include regularTextStyling(7px, normal, $font-500);
        flex: 1;
        color: #000;

        &:first-child {
          margin-left: -6px;
        }
        &:nth-child(2) {
          margin-left: 48px;
        }
        &:nth-child(5) {
          margin-left: 0px;
        }

        &:last-child {
          flex: 0 0 0;
          margin-right: -6px;
        }
      }
      .whiteText {
        color: $white-color;
      }
    }

    .progress-bar {
      border-radius: 0px 0px 3.45px 3.45px;
    }
  }

  &__progress {
    &:not(&:first-child) {
      flex: 1;
    }

    &:first-child {
      border-left: 1px solid #e22400;
      border-right: 1px solid #e22400;
    }
    &:nth-child(2) {
      border-right: 1px solid #fe6a00;
    }
    &:nth-child(3) {
      border-right: 1px solid #feba65;
    }
    &:nth-child(4) {
      border-right: 1px solid #85cb82;
    }
    &:nth-child(5) {
      border-right: 1px solid #61b337;
    }

    p {
      padding: 7px 15px;
      color: $white-color;
      text-align: center;
      margin-top: 13px;
      @include regularTextStyling(7px, normal, 400);
    }
  }
}

.transaction-summary {
  width: 260px;
  background: rgba(0, 0, 0, 0.06);
  // padding: 17px 38px 20px 11px;
  border-radius: 9px;

  h5 {
    margin-right: 8px;
    font-weight: 500;
    font-size: 14px;
    @include regularTextStyling($text-sm, 20px, $font-600);
  }

  .amount {
    margin-top: 4px;
    color: $black-100;
    @include regularTextStyling($text-2xl, normal, $font-600);
  }

  .info {
    color: #9a9a9a;
    margin: 12px 0 18px 0;
    @include regularTextStyling($text-xl, 140%, 400);
  }

  .status {
    color: #ebf9ee;
    text-align: center;
    border-radius: 6px;

    padding: 4px 8px;
    @include regularTextStyling(8px, normal, 400);
  }

  .summary-card__button-wrapper {
    .btn-1 {
      background-color: #fff;
      color: #1c1c1c;
      border-radius: 5px;
      padding: 6px 12px;

      @include regularTextStyling(12px, normal, 400);
    }
  }
}

.fourth-card-header {
  padding: 17px 23px 20px 11px !important;
}
.complete-profile {
  width: 100%;
  height: min-content;
  min-height: 180px;
  border-radius: 16px;
  margin-top: 39px;
  padding: 22px 0 0 25px;

  color: #fff;
  @include generateBorderAttr($color: #f0f0f0);
  @include displayFlex(flex-start, space-between);

  &__left {
    max-width: 600px;
    width: fit-content;
    h1 {
      margin-right: 41px;
      // @include regularTextStyling($text-2xl, normal, 500);
    }
    p {
      color: #ababab;
      margin: 8px 0 20px 0;
      // @include regularTextStyling($text-xl, normal, 400);
    }
  }

  &__image-wrapper {
    margin-top: 14px;

    img {
      // width: 145px;
      // height: 145px;
      object-fit: contain;
      overflow: hidden;
    }
  }
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 21px;

  th,
  td {
    text-align: left;
    padding: 8px 16px;
  }

  td {
    color: $black-100;
    @include regularTextStyling($text-xl, 18px, 400);
    @include generateBorderAttr(border-bottom, 1px, solid, $primary-tint-6);

    &:last-child {
      @include regularTextStyling($text-xl, 18px, $font-500);
    }
  }

  .horizontal-cell {
    &:last-child {
      border-radius: 5px;
      background: #000;
      color: $white-color;
      font-size: 12px;
    }
  }

  th {
    color: rgba(0, 0, 0, 0.4);
    border-right: 1px solid $primary-tint-6;
    @include generateBorderAttr(border-bottom, 1px, solid, $black-8);
    @include regularTextStyling($text-xl, 18px, $font-500);

    &:last-child {
      border-right: none;
    }
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
    object-fit: cover;
  }
}

.transaction-list {
  @include displayFlex(center, space-between);
  margin: 20px 0 16px 0;

  &:last-child {
    margin-bottom: 0;
  }

  div {
    h3 {
      color: $black-100;
      @include regularTextStyling($text-sm, 18px, $font-500);
    }
  }

  &__date {
    margin-top: 4px;
    color: #999;
    @include regularTextStyling($text-xl, 18px, 400);
  }

  &__amount {
    color: $black-100;
    @include regularTextStyling(12px, 36px, $font-500);
  }
}
