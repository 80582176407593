$black-color: #344054;

.main-sidebar {
  @include generateBorderAttr($color: $primary-tint-6);

  &__image-wrapper {
    margin-bottom: 54px;
    background-color: #0e6871;
    // padding: 28px 73px 0 32px;
  }

  &__nav {
    margin: 0 30px 0 20px;

    .list-text {
      // color: #1e223e;
      color: #344054;
      margin-left: 10px;
      font-weight: 500 !important;
      @include regularTextStyling(13.33px, 160%);

      &:hover {
        color: #159aa8;
      }
    }
  }

  &__active {
    background-color: #e4f9fc;
    border-radius: 8px;

    .list-text {
      color: #159aa8;
    }
  }

  &__item {
    padding: 8px;
    margin: 5px 0;
    width: 100%;
    border-radius: 5px;

    &:hover:not([__active]) {
      border-radius: 8px;
      background-color: #e4f9fc;
      margin: 5px 0;
      color: $black-color;
      cursor: pointer;

      .list-text {
        color: #159aa8;
      }
    }
  }
}
